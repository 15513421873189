import React from "react";
// import anime from "animejs";

// import { makeStyles } from "@material-ui/core/styles";

// const useStyles = makeStyles((theme) => ({
//   blinking: {
//     borderRadius: "50%",
//     width: "30px",
//     height: "30px",
//     marginLeft: "calc(50% - 16px)",
//     marginBottom: theme.spacing(-2),
//   },
// }));

const MemberCard = (props) => {
  // const classes = useStyles();

  // useEffect(() => {
  //   anime({
  //     targets: [document.querySelector("#blinking" + props.id)],
  //     boxShadow: [
  //       "0 0 0 white, inset 0 0 40px red",
  //       "0 0 500px red, inset 0 0 40px red",
  //     ],
  //     duration: 1000,
  //     easing: "easeInQuart",
  //     loop: true,
  //   });
  // });

  return (
    <>
      {/* <div id={"blinking" + props.id} className={classes.blinking}></div> */}
      <img
        style={{ zIndex: "-1", maxWidth: "50%" }}
        src={`${process.env.PUBLIC_URL}assets/images/E4.png`}
        alt=""
      />
    </>
  );
};

export default MemberCard;
