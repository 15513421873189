import axios from "axios";

const url = "https://pven9qjvi4.execute-api.eu-west-3.amazonaws.com/sendMail";

const sendMail = (metadata, callback) => {
  axios
    .get(
      `${url}/${metadata.firstName}/${metadata.lastName}/${metadata.email}/${metadata.profileType}/${metadata.country}/${metadata.object}/${metadata.content}`
    )
    .then((res) => {
      console.log(false, res);
      callback(false, res);
    })
    .catch((error) => {
      console.log(true, error);
      callback(true, error);
    });
};

export { sendMail };
