import { useState } from "react";
import { AlertContext, LanguageContext } from "./contexts";
import { availableLanguages } from "./translator";

const LanguageProvider = (props) => {
  const [language, setLanguage] = useState("fr");
  const changeLanguage = (newLanguage) => {
    if (availableLanguages.indexOf(newLanguage) > -1) setLanguage(newLanguage);
  };
  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {props.children}
    </LanguageContext.Provider>
  );
};

const AlertProvider = (props) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");

  const notify = (message, severity) => {
    setOpen(true);
    setMessage(message);
    setSeverity(severity);
  };

  const recall = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setMessage("");
    setSeverity("");
  };

  return (
    <AlertContext.Provider value={{ open, message, severity, notify, recall }}>
      {props.children}
    </AlertContext.Provider>
  );
};

export { AlertProvider, LanguageProvider };
