const logo ="\n"+ 
"\n"+ 
"\n"+ 
"\n"+ 
"                                           ...                               \n"+  
"                                        ./@@@@@@                              \n"+  
"   *@                                 .  .(  @@@@                @@@@@@@@@.   \n"+  
"    &* &#@*                         . .(   @@@@(           @@@@@@@@(  /@@%    \n"+  
"     @@,@    .@@@    .@           @ (   @@@@@         @%#@@@@%       /@@@     \n"+  
"      @@@@*        &@    &@     @(   @@@@@        @@@@@ @&          @@@@      \n"+  
"       ,@@@@*           &   @@@     @@@%       @@@@@@             (@@@.       \n"+  
"         ,@@@@*             &  @@@% @@@     @@@@@&              @@@@.         \n"+  
"              @@@/             @# @@@@@&@@@@@@               @@@@&.           \n"+  
"              .@@@/                @ @@@@@@@            @@@@@@@               \n"+  
"                   @@                @% @@@@@@@@@@@@@@@@@@@                   \n"+  
"                         .@@@@@@@# @@@@%@ @@@                                 \n"+  
"                                 @@@@@@@@@@@@@&                               \n"+  
"                                 @@@@@@@ @@@@@@                               \n"+  
"                                   @@@@@@@@@@                                 \n"+  
"                                                                    \n"+  
"      #        ###     #     #                                                  \n"+  
"     # #        #      ##   ## ###### #####   ####  ###### #    #  ####  ###### \n"+  
"    #   #       #      # # # # #      #    # #    # #      ##   # #    # #      \n"+  
"   #     #      #      #  #  # #####  #    # #      #####  # #  # #      #####\n"+  
"   ####### ###  #  ### #     # #      #####  #  ### #      #  # # #      #      \n"+  
"   #     # ###  #  ### #     # #      #   #  #    # #      #   ## #    # #      \n"+  
"   #     # ### ### ### #     # ###### #    #  ####  ###### #    #  ####  ######  \n"+                                                        
"\n\n\n\n\n"                                                                     
                                                                                
                                                                                
                                                                                
export default logo                                              
                                                                                
