import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { ListItem, ListItemText } from "@material-ui/core";

const useStyles = makeStyles({
  linkText: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "white",
    "&:hover": {
      color: "#57C8F5",
    },
  },
});

const MenuButton = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <Link to={"/" + props.meta.target} className={classes.linkText}>
      <ListItem button>
        <ListItemText primary={localize(props.meta.name, language)} />
      </ListItem>
    </Link>
  );
};

export default MenuButton;
