import React, { useContext } from "react";
import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";

import CenteredContent from "../components/CenteredContent";
import MemberCard from "../components/MemberCard";
import PaperWithTitle from "../components/PaperWithTitle";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(2, 0),
    fontSize: "1.2rem",
  },
  fullImage: {
    maxWidth: "100%",
    margin: 0,
    padding: 0,
  },
  title: {
    fontWeight: "bold",
    margin: theme.spacing(6, 0),
  },
}));

const AboutPage = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <>
      <img
        src={`${process.env.PUBLIC_URL}/assets/images/about_team.jpg`}
        width="100%"
        className={classes.fullImage}
        alt=""
      />

      <Container component="main" maxWidth="xl">
        <CenteredContent title="about.valuesTitle">
          <PaperWithTitle style={{ backgroundColor: "white" }}>
            {localize("about.values", language).map((content, index) => (
              <Typography
                variant="body1"
                align="justify"
                key={`aboutTypography${index}`}
                className={classes.content}
              >
                {content}
              </Typography>
            ))}
          </PaperWithTitle>
        </CenteredContent>

        <CenteredContent title="about.foundersTitle" margin={2}>
          <Grid container justify="space-around" spacing={3}>
            {localize("about.founders", language).map((person, index) => (
              <Grid item xs={12} md={3} key={`aboutGridFounders${index}`}>
                <MemberCard meta={person}></MemberCard>
              </Grid>
            ))}
          </Grid>
        </CenteredContent>

        <CenteredContent title="about.permanentTitle" margin={2}>
          <Grid container justify="space-around" spacing={3}>
            {localize("about.permanent", language).map((person, index) => (
              <Grid item xs={12} md={3} key={`aboutGridPermanent${index}`}>
                <MemberCard meta={person}></MemberCard>
              </Grid>
            ))}
          </Grid>
        </CenteredContent>

        <CenteredContent title="about.temporaryTitle" margin={2}>
          <Grid container justify="space-around" spacing={3}>
            {localize("about.temporary", language).map((person, index) => (
              <Grid item xs={12} md={3} key={`aboutGridTemporary${index}`}>
                <MemberCard meta={person}></MemberCard>
              </Grid>
            ))}
          </Grid>
        </CenteredContent>

        <CenteredContent title="about.partnersTitle" margin={1}>
          <Grid container justify="space-around" spacing={3}>
            {localize("about.partners", language).map((partner, index) => (
              <Grid item xs={4} md={2} key={`aboutGridPartners${index}`}>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/${partner.image}`}
                  style={{ maxWidth: "95%", maxHeight: "50%" }}
                  alt={partner.name}
                />
              </Grid>
            ))}
          </Grid>
        </CenteredContent>
      </Container>
    </>
  );
};

export default AboutPage;
