import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { LanguageContext } from "../utils/contexts";
import { localize, getAllArticles } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";

import CenteredContent from "../components/CenteredContent";
import NewsCarousel from "../components/NewsCarousel";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    height: 120,
  },
  cardTitle: {
    fontWeight: "bold",
    margin: theme.spacing(1, 0),
    height: 60,
  },
  button: {
    color: "white",
    backgroundColor: "#293039",
    margin: theme.spacing(2, 0, 0),
    maxWidth: "20em",
    "&:hover": {
      backgroundColor: "#146B9C",
    },
  },
  image: {
    height: 250,
    width: "100%",
    textAlign: "center",
  },
  title: {
    fontWeight: "bold",
    margin: theme.spacing(6, 0),
  },
}));

const NewsPage = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  const articlesList = getAllArticles(language);

  return (
    <Container component="main" maxWidth="xl">
      <CenteredContent title="news.title" margin={2}>
        <NewsCarousel />
      </CenteredContent>

      <CenteredContent title="news.blog" margin={2}>
        <Grid container spacing={3}>
          {articlesList.map((article, index) => (
            <Grid item xs={12} md={6} key={`newsGrid${index}`}>
              <Card style={{ maxWidth: "95%" }}>
                <CardMedia classes={{ root: classes.image }}>
                  <img
                    src={article["cover"] || "http://placehold.it/255x100"}
                    className={classes.image}
                    alt=""
                  />
                </CardMedia>
                <CardContent>
                  <Typography
                    variant="h5"
                    align="justify"
                    className={classes.cardTitle}
                  >
                    {article["title"]}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    align="justify"
                    className={classes.cardContent}
                  >
                    {article["short"]}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Link to={`/articles/${article["url"]}`}>
                    <Button variant="contained" className={classes.button}>
                      {localize("news.readmore", language)}
                    </Button>
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </CenteredContent>
    </Container>
  );
};

export default NewsPage;
