import React, { useState } from "react";
import { menuButtons, homeButton, availablePages } from "../assets/menuButtons";

import { makeStyles } from "@material-ui/core/styles";
import { Drawer, IconButton, List } from "@material-ui/core";
import { Menu } from "@material-ui/icons";

import MenuButton from "../components/MenuButton";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  linkText: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#293039",
  },
  paper: {
    backgroundColor: "#293039",
  },
});

const SideDrawer = () => {
  const classes = useStyles();
  
  const [state, setState] = useState({ right: false });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ [anchor]: open });
  };

  const sideDrawerList = (anchor) => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List component="nav">
        <MenuButton meta={homeButton} />
        {availablePages.map((menuButton, index) => (
          <MenuButton meta={menuButtons[menuButton]} key={`sideDrawer${index}`} />
        ))}
      </List>
    </div>
  );

  return (
    <>
      <IconButton
        edge="start"
        aria-label="menu"
        onClick={toggleDrawer("right", true)}
      >
        <Menu fontSize="large" style={{ color: "white" }} />
      </IconButton>

      <Drawer
        anchor="right"
        open={state.right}
        onOpen={toggleDrawer("right", true)}
        onClose={toggleDrawer("right", false)}
        classes={{ paper: classes.paper }}
      >
        {sideDrawerList("right")}
      </Drawer>
    </>
  );
};

export default SideDrawer;
