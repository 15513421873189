import React, { useContext, useEffect, useState } from "react";
import anime from "animejs";

import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Typography } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";

import E43DView from "../components/E43DView";

const useStyles = makeStyles((theme) => ({
  divTitleContainer: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
  },
  header: {
    height: "100vh",
    backgroundColor: "#293039",
    display: "flex",
    maxWidth: "100%",
  },
  imageContainer: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
  },
  titleContainer: {
    paddingTop: "18%",
    textAlign: "center",
    position: "relative",
  },
}));

const HomePage = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  const [height, set_height] = useState(0);
  const [reference, setReference] = useState(0);
  const [display_logo, set_display_logo] = useState(false);
  const [logo_opacity, set_logo_opacity] = useState(0);

  useEffect(() => {
    const height =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    set_height(height);
    setReference(width > height ? width : height);

    var textWrapper = document.querySelectorAll(".ai-mergence-title");
    for (let i = 0; i < textWrapper.length; i++) {
      textWrapper[i].innerHTML = textWrapper[i].textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      );
    }

    anime
      .timeline({ loop: false })
      .add({
        targets: ".ai-mergence-title .letter",
        opacity: [0, 1],
        easing: "easeInOutQuad",
        delay: (el, i) => 70 * (i + 1),
      })
      .add({
        targets: "#title",
        translateY: {
          value: 180,
          duration: 5000,
        },
        update: (anim) => {
          if (anim.progress > 10) {
            set_display_logo(true);
            set_logo_opacity(anim.progress / 100.0);
          } else {
            set_logo_opacity(0);
          }
        },
      });

    anime({
      targets: ".scroll",
      translateY: 20,
      loop: true,
      direction: "alternate",
      easing: "easeInOutSine",
    });
  }, []);

  return (
    <Grid container className={classes.header}>
      <Hidden smDown>
        <Grid item xs={12} md={6}>
          <E43DView />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6}>
        <div id="title-section" className={classes.titleContainer}>
          <div
            className={classes.ImageContainer}
            style={{ top: (reference / 100) * 20 }}
          >
            {display_logo && (
              <img
                src="assets/images/logo.png"
                width="50%"
                style={{ opacity: logo_opacity }}
                alt=""
              />
            )}
          </div>

          <div
            id="title"
            className={classes.divTitleContainer}
            style={{ top: (height / 100) * 30 }}
          >
            <Typography
              className="ai-mergence-title"
              variant="h1"
              style={{ fontSize: "4em", color: "white", fontWeight: "bold" }}
            >
              {localize("app.name", language)}
            </Typography>
            <Typography
              className="ai-mergence-title"
              variant="h3"
              align="center"
              style={{ fontSize: "3em", color: "white", fontWeight: "bold" }}
            >
              {localize("home.catchphrase", language)}
            </Typography>
          </div>
        </div>
        <div
          className="scroll"
          style={{
            position: "absolute",
            top: "80vh",
            color: "white",
          }}
        >
          <ExpandMore style={{ fontSize: "5em" }} />
        </div>
      </Grid>
    </Grid>
  );
};

export default HomePage;
