import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(0, 4),
  },
  imageContainer: {
    position: "relative",
    textAlign: "center",
  },
  square: {
    width: "50px",
    height: "50px",
    backgroundColor: "#0b82b1",
    position: "absolute",
    top: "15%",
    left: "50%",
    transform: "translate(-50%, -15%) rotateZ(45deg)",
  },
  title: {
    fontWeight: "bold",
    margin: theme.spacing(6, 0),
    verticalAlign: "center",
  },
}));

const ExpertCard = (props) => {
  const classes = useStyles();

  const [isSM, setSM] = useState(false);
  useEffect(() => {
    function handleResize() {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      setSM(width <= 960);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const makeImage = (side) => {
    let styleGrid = {};
    let styleImg = {
      borderRadius: "100%",
      width: "200px",
    };
    if (!isSM) {
      styleGrid["border" + side] = "10px solid #0b82b1";
      styleImg = {
        ...styleImg,
        [side.toLowerCase()]: "20%",
        transform: `translate(${side === "Left" ? "-" : "+"}20%, 50%)`,
        position: "absolute",
        top: "50%",
      };
    }

    return (
      <Grid item xs={12} md={6} className={classes.gridItem} style={styleGrid}>
        <div className={classes.imageContainer}>
          <img
            src={`${process.env.PUBLIC_URL}assets/images/${props.expert.image}`}
            style={styleImg}
            alt=""
          />
        </div>
      </Grid>
    );
  };

  const makeDescription = (side) => {
    let styleGrid = {};
    if (!isSM) styleGrid["border" + side] = "10px solid #0b82b1";
    return (
      <Grid item xs={12} md={6} className={classes.gridItem} style={styleGrid}>
        <Typography variant="h4" align="center" className={classes.title}>
          {props.expert.title}
        </Typography>
        {props.expert.description.map((row, index) => (
          <Typography
            key={index}
            variant="body1"
            align="justify"
            className={classes.content}
          >
            {row}
          </Typography>
        ))}
      </Grid>
    );
  };

  const makeSquare = () => {
    return <div className={classes.square}></div>;
  };

  if (isSM || props.side === "left") {
    return (
      <div style={{ position: "relative" }}>
        <Grid container>
          {makeImage("Right")}
          {makeDescription("Left")}
        </Grid>
        {!isSM && makeSquare()}
      </div>
    );
  } else {
    return (
      <div style={{ position: "relative" }}>
        <Grid container>
          {makeDescription("Right")}
          {makeImage("Left")}
        </Grid>
        {!isSM && makeSquare()}
      </div>
    );
  }
};

export default ExpertCard;
