import { useContext } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import { AlertContext } from "../utils/contexts";

import AboutPage from "./AboutPage";
import ContactPage from "./ContactPage";
import ExpertPage from "./ExpertPage";
import HomePage from "./HomePage";
import InvestPage from "./InvestPage";
import NewsArticlePage from "./NewsArticlePage";
import NewsNewsPage from "./NewsNewsPage";
import NewsPage from "./NewsPage";
import ProductPage from "./ProductPage";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Notification from "../components/Notification";

const PageSelector = (props) => {
  const { open, message, severity, recall } = useContext(AlertContext);

  return (
    <Router>
      <div className="rootContainer">
        <Header />

        <main className="rootExceptFooter">
          <Switch>
            <Route path="/about">
              <AboutPage />
            </Route>
            <Route path="/articles/:articleName">
              <NewsArticlePage />
            </Route>
            <Route path="/contact">
              <ContactPage />
            </Route>
            <Route path="/expert">
              <ExpertPage />
            </Route>
            <Route path="/invest">
              <InvestPage />
            </Route>
            <Route path="/news/:newsName">
              <NewsNewsPage />
            </Route>
            <Route path="/news">
              <NewsPage />
            </Route>
            <Route path="/product">
              <ProductPage />
            </Route>
            <Route path="/">
              <HomePage />
            </Route>
          </Switch>
        </main>
        <footer className="rootFooter">
          <Footer />
        </footer>
      </div>
      <Notification
        snackbarOpen={open}
        snackbarMessage={message}
        snackbarSeverity={severity}
        handleClose={recall}
      />
    </Router>
  );
};

export default PageSelector;
