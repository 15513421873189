import React, { useEffect, useState } from "react";

import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import ReactLeafletDriftMarker from "react-leaflet-drift-marker";
import L from "leaflet";

const E4Icon = L.icon({
  iconUrl: `${process.env.PUBLIC_URL}/assets/images/E4.png`,
  iconSize: [30, 26],
  iconAnchor: [15, 13],
  popupAnchor: [0, -15],
});

const AIMergenceIcon = L.icon({
  iconUrl: `${process.env.PUBLIC_URL}/assets/images/AIMergenceIcon.png`,
  iconSize: [30, 26],
  iconAnchor: [15, 13],
  popupAnchor: [0, -15],
});

const E4Position0 = { lat: 48.834211, lng: 2.37102 };
const E4Position1 = { lat: 48.832661, lng: 2.372437 };

function contains(bounds, lat, lng) {
  //https://rosettacode.org/wiki/Ray-casting_algorithm
  var count = 0;
  for (var b = 0; b < bounds.length; b++) {
    var vertex1 = bounds[b];
    var vertex2 = bounds[(b + 1) % bounds.length];
    if (west(vertex1, vertex2, lng, lat)) ++count;
  }
  return count % 2;

  /**
   * @return {boolean} true if (x,y) is west of the line segment connecting A and B
   */
  function west(A, B, x, y) {
    if (A.y <= B.y) {
      if (y <= A.y || y > B.y || (x >= A.x && x >= B.x)) {
        return false;
      } else if (x < A.x && x < B.x) {
        return true;
      } else {
        return (y - A.y) / (x - A.x) > (B.y - A.y) / (B.x - A.x);
      }
    } else {
      return west(B, A, x, y);
    }
  }
}

const StationFBound = [
  { x: 48.8321, y: 2.37678 },
  { x: 48.8344, y: 2.37035 },
  { x: 48.8346, y: 2.3711 },
  { x: 48.8323, y: 2.3733 },
];

const LeafMap = (props) => {
  useEffect(() => {
    repeat();
  });

  function openPopup(marker) {
    if (marker) {
      marker.openPopup();
    }
  }

  const gen_position = (position) => {
    let nextPoint = [
      0.0002 * ((Math.random() - 0.5) * 2) + position.lat,
      0.0002 * ((Math.random() - 0.5) * 2) + position.lng,
    ];
    // console.log(nextPoint, contains(StationFBound, nextPoint[1], nextPoint[0]));
    if (!contains(StationFBound, nextPoint[1], nextPoint[0]))
      return { lng: position.lng, lat: position.lat };

    return {
      lng: nextPoint[1],
      lat: nextPoint[0],
    };
  };

  const [state, setState] = useState({
    E4Position0: E4Position0,
    E4Position1: E4Position1,
  });

  const repeat = () => {
    setTimeout(() => {
      setState({
        E4Position0: gen_position(state.E4Position0),
        E4Position1: gen_position(state.E4Position1),
      });
    }, 1000);
  };

  return (
    <MapContainer
      center={[
        (state.E4Position0.lat + state.E4Position1.lat) / 2,
        (state.E4Position0.lng + state.E4Position1.lng) / 2,
      ]}
      zoom={17}
      scrollWheelZoom={false}
      style={{ width: "100%", height: "100%" }}
      attributionControl={false}
    >
      <TileLayer url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}.png" />
      <Marker
        position={{ lat: 48.83355938506907, lng: 2.3715806255396455 }}
        icon={AIMergenceIcon}
        ref={openPopup}
      >
        <Popup>
          <div>
            <h4 style={{ textAlign: "center", margin: 0 }}>A.I.Mergence</h4>
            <p style={{ margin: 0 }}>5 Rue Eugène Freyssinet</p>
            <p style={{ margin: 0 }}>75013 Paris</p>
            <p style={{ margin: 0 }}>France</p>
          </div>
        </Popup>
      </Marker>
      <ReactLeafletDriftMarker
        position={state.E4Position0}
        duration={1000}
        icon={E4Icon}
      ></ReactLeafletDriftMarker>
      <ReactLeafletDriftMarker
        position={state.E4Position1}
        duration={1000}
        icon={E4Icon}
      ></ReactLeafletDriftMarker>
    </MapContainer>
  );
};

export default LeafMap;
