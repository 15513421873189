import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Grid, Typography } from "@material-ui/core";

import CenteredContent from "../components/CenteredContent";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "white",
    backgroundColor: "#293039",
    margin: theme.spacing(2, 0, 1),
    "&:hover": {
      backgroundColor: "#146B9C",
    },
  },
  content: {
    marginTop: theme.spacing(2),
  },
  image: {
    width: "100%",
    margin: theme.spacing(0, 0, 1, 0),
  },
  title: {
    marginTop: theme.spacing(5),
  },
}));

const InvestPage = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xl">
      <CenteredContent title="" margin={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <img
              src={`${process.env.PUBLIC_URL}assets/images/E4_text.png`}
              className={classes.image}
              alt=""
            />
          </Grid>

          <Grid item xs={12} md={6} style={{ marginTop: "5%" }}>
            <Typography variant="h4" className={classes.title}>
              {localize("invest.title", language)}
            </Typography>
            {localize("invest.content", language).map((row, index) => (
              <Typography
                variant="body1"
                align={"justify"}
                className={classes.content}
                key={`investTypography${index}`}
              >
                {row}
              </Typography>
            ))}
            <Link to={"/contact"}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className={classes.button}
              >
                {localize("invest.button", language)}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </CenteredContent>
    </Container>
  );
};

export default InvestPage;
