const menuButtons = {
  product: {
    name: "menu.product",
    target: "product",
  },
  about: {
    name: "menu.about",
    target: "about",
  },
  news: {
    name: "menu.news",
    target: "news",
  },
  invest: {
    name: "menu.invest",
    target: "invest",
  },
  expert: {
    name: "menu.expert",
    target: "expert",
  },
  contact: {
    name: "menu.contact",
    target: "contact",
  },
};

const homeButton = {
  name: "menu.home",
  target: "",
};

const availablePages = Object.keys(menuButtons);

export { menuButtons, homeButton, availablePages };
