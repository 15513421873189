import { useContext } from "react";
import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const PaperWithTitle = (props) => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);

  return (
    <Grid item xs={12}>
      <Paper elevation={0} className={classes.paper} style={props.style || {}}>
        <Typography component="h1" variant="h6">
          {localize(props.title, language)}
        </Typography>
        {props.children}
      </Paper>
    </Grid>
  );
};

export default PaperWithTitle;
