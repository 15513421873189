import React from "react";
import ReactMarkdown from "react-markdown";

import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import HTMLRenderer from 'react-html-renderer'

// https://github.com/remarkjs/react-markdown/blob/22bb78747d768181cb9ea8711b5e13c3768921d8/src/renderers.js

const Markdown = (props) => {
  const classes = makeStyles((theme) => ({
    container: {
      margin: theme.spacing(2, 0),
    },
    em: {
      color: "#57C8F5",
      fontWeight: "bold",
    },
    strong: {
      color: "#0b82b1",
      fontWeight: "bold",
      fontSize: "1.5em",
    },
    image: {
      maxWidth: "100%",
      display: "block",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      marginRight: "auto",
      marginLeft: "auto",
    },
  }))();

  const renderers = {
    emphasis: ({ node, ...props }) => (
      <span className={classes.em} {...props} />
    ),
    strong: ({ node, ...props }) => (
      <span className={classes.strong} {...props} />
    ),
    image: ({ alt, src, title }) => (
      <img alt={alt} src={src} title={title} className={classes.image} />
    ),
  };

  return (
    <>
      {typeof (props.children) === 'string' &&
        <Typography
          variant={props.variant || "body1"}
          align={props.align || "justify"}
          className={classes.container}
        >
          {
            props.children[0] === '<' ?
              <HTMLRenderer html={props.children} /> :
              <ReactMarkdown renderers={renderers}>{props.children}</ReactMarkdown>
          }
        </Typography>
      }
    </>

  );
};

export default Markdown;
