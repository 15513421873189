import { AlertProvider, LanguageProvider } from "./utils/providers";
import PageSelector from "./pages/PageSelector";
import logo from "./assets/text/logo";

const App = () => {
  
  console.log(logo)

  return (
    <AlertProvider>
      <LanguageProvider>
        <PageSelector />
      </LanguageProvider>
    </AlertProvider>
  );
};

export default App;
