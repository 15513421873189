import React, { useContext, useState } from "react";
import { AlertContext, LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";
import { sendMail } from "../utils/api";

import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Hidden,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

import FlatButton from "../components/FlatButton";
import LeafMap from "../components/LeafMap";
import PaperWithTitle from "../components/PaperWithTitle";
// import SelectInGrid from "../components/SelectInGrid";
import TextFieldInGrid from "../components/TextFieldInGrid";

const useStyles = makeStyles((theme) => ({
  currentButton: {
    color: "white",
    backgroundColor: "#293039",
    margin: theme.spacing(2, 0, 1),
    "&:hover": {
      backgroundColor: "#146B9C",
    },
  },
  image: {
    width: "100%",
    margin: theme.spacing(0, 0, 1, 0),
  },
  title: {
    fontWeight: "bold",
    margin: theme.spacing(3, 0, 6, 0),
  },
}));

const ContactPage = (props) => {
  const { notify } = useContext(AlertContext);
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  const [state, setState] = useState({
    firstName: "",
    lastName: "",
    email: "",
    profileType: "",
    country: "",
    object: "",
    content: "",
  });

  const [errorState, setErrorState] = useState({
    firstName: false,
    lastName: false,
    email: false,
    profileType: false,
    country: false,
    object: false,
    content: false,
  })

  const handleTextInputChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleRadioChange = (event) => {
    setState({
      ...state,
      profileType: event.target.value,
    });
  };

  // const handleSelectChange = (event, child) => {
  //   setState({
  //     ...state,
  //     object: child.props.children,
  //   });
  // };

  const handleMail = () => {
    setErrorState({
      firstName: state.firstName === "",
      lastName: state.lastName === "",
      email: state.email === "",
      profileType: state.profileType === "",
      country: state.country === "",
      object: state.object === "",
      content: state.content === "",
    })
    if (state.firstName === "" ||
      state.lastName === "" ||
      state.email === "" ||
      state.profileType === "" ||
      state.country === "" ||
      state.object === "" ||
      state.content === ""
    ) {
      notify("contact.alert.form", "error");
      return;
    }
    sendMail(state, (error, data) => {
      if (error) {
        notify("contact.alert.error", "error");
      } else {
        notify("contact.alert.success", "success");
      }
    });
  };

  return (
    <Container component="main" maxWidth="xl">
      <Grid container spacing={3} style={{ minHeight: "85vh" }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{ textAlign: "center", marginTop: "5%" }}
        >
          <Typography variant="h3" align="center" className={classes.title}>
            {localize("contact.title", language)}
          </Typography>
          <PaperWithTitle title="Contact us">
            <form className={classes.form} noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormControl component="fieldset">
                    <FormLabel component="legend">
                      {localize("contact.iam", language)}
                    </FormLabel>
                    <RadioGroup
                      aria-label="profile"
                      name="profile"
                      onChange={handleRadioChange}
                      row
                      style={errorState.profileType ? { color: 'red' } : {}}
                    >
                      <FormControlLabel
                        value="company"
                        control={<Radio />}
                        label={localize("contact.company", language)}
                      />
                      <FormControlLabel
                        value="investor"
                        control={<Radio />}
                        label={localize("contact.investor", language)}
                      />
                      <FormControlLabel
                        value="press"
                        control={<Radio />}
                        label={localize("contact.press", language)}
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label={localize("contact.other", language)}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <TextFieldInGrid
                  error={errorState.lastName}
                  name={"lastName"}
                  label={"contact.lastname"}
                  onChange={handleTextInputChange}
                />
                <TextFieldInGrid
                  error={errorState.firstName}
                  name={"firstName"}
                  label={"contact.firstname"}
                  onChange={handleTextInputChange}
                />
                <TextFieldInGrid
                  error={errorState.country}
                  name={"country"}
                  label={"contact.country"}
                  onChange={handleTextInputChange}
                  fullwidth
                />
                <TextFieldInGrid
                  error={errorState.email}
                  name={"email"}
                  label={"contact.email"}
                  onChange={handleTextInputChange}
                  fullwidth
                />
                <TextFieldInGrid
                  error={errorState.object}
                  name={"object"}
                  label={"contact.object"}
                  onChange={handleTextInputChange}
                  fullwidth
                />
                {/* <SelectInGrid
                  name="object"
                  label={"contact.object"}
                  options={"contact.objectOptions"}
                  onChange={handleSelectChange}
                  fullwidth
                  error={errorState.object}
                /> */}
                <TextFieldInGrid
                  error={errorState.content}
                  name={"content"}
                  label={"contact.message"}
                  onChange={handleTextInputChange}
                  fullwidth
                  multiline
                />
              </Grid>
              <FlatButton
                meta={{
                  name: "contact.submit",
                  color: (theme) => "#293039",
                  target: handleMail,
                }}
              />
            </form>
          </PaperWithTitle>
          <Typography variant="h3" align="center" className={classes.title}>
            {localize("contact.careerTitle", language)}
          </Typography>
          <Typography variant="body1" align="center">
            {localize("contact.careerBody", language)}
          </Typography>
          <Typography variant="body1" align="center">
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              className={classes.currentButton}
              href="https://jobs.stationf.co/companies/a-i-mergence"
            >
              {localize("contact.careerButton", language)}
            </Button>
          </Typography>
        </Grid>
        <Hidden smDown>
          <Grid item xs={12} md={6}>
            <div style={{ height: "100%", width: "100%" }}>
              <LeafMap />
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </Container>
  );
};

export default ContactPage;
