import localeFr from "../assets/locales/fr.json";
import localeEn from "../assets/locales/en.json";
const articlesMapper = require("../assets/articles/loader").default;
const newsMapper = require("../assets/news/loader").default;

const locales = { fr: localeFr, en: localeEn };
const availableLanguages = Object.keys(locales);

const localize = (quote, language) => {
  try {
    return locales[language][quote];
  } catch (error) {
    return quote + " not found in " + language + " locale";
  }
};

const getAllCategory = (language, mapper) => {
  var categoryList = [];
  const keys = Object.keys(mapper[language]);
  keys.forEach((categoryOne) => {
    const current = mapper[language][categoryOne];
    if (current["active"]) {
      categoryList.push({
        url: categoryOne,
        title: current["title"],
        cover: current["cover"],
        short: current["short"].substring(0, 140) + "...",
        content: current["content"],
      });
    }
  });
  return categoryList;
};

const getOneCategory = (language, title, mapper) => {
  return mapper[language][title] || null;
};

const getAllArticles = (language) => {
  return getAllCategory(language, articlesMapper);
};

const getOneArticles = (language, title) => {
  return getOneCategory(language, title, articlesMapper);
};

const getAllNews = (language) => {
  return getAllCategory(language, newsMapper);
};

const getOneNews = (language, title) => {
  return getOneCategory(language, title, newsMapper);
};

export {
  localize,
  availableLanguages,
  getAllArticles,
  getOneArticles,
  getAllNews,
  getOneNews,
};
