import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Hidden, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "white",
    backgroundColor: "#293039",
    margin: theme.spacing(2, 0, 0),
    maxWidth: "20em",
    "&:hover": {
      backgroundColor: "#146B9C",
    },
  },
  content: {
    margin: theme.spacing(2, 0),
  },
  gridContainer: {
    position: "relative",
    marginBottom: "5em",
  },
  gridItem: {
    margin: theme.spacing(2, 0),
  },
  halfImage: {
    width: "50%",
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  fullImage: {
    width: "100%",
  },
  title: {
    fontWeight: "bold",
    margin: theme.spacing(6, 0),
    verticalAlign: "center",
  },
}));

const HomeGrid = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  const fillImage = `${process.env.PUBLIC_URL}assets/images/${props.image}`;
  const fillTitle = localize(`home.${props.name}Title`, language);
  const fillContent = localize(`home.${props.name}Content`, language);
  const fillLink = localize(`home.${props.name}Link`, language);

  const makeImage = (size) => {
    return (
      <Grid item xs={12} md={props.width} className={classes.gridItem}>
        <img
          src={fillImage}
          className={size === "full" ? classes.fullImage : classes.halfImage}
          alt=""
        />
      </Grid>
    );
  };

  const makeDescription = () => {
    return (
      <Grid item xs={12} md={12 - props.width} className={classes.gridItem}>
        <Typography variant="h4" align="center" className={classes.title}>
          {fillTitle}
        </Typography>
        <Typography variant="h6" align="center" className={classes.content}>
          {fillContent}
        </Typography>
        <Typography variant="h4" align="center">
          <Link to={"/" + props.link}>
            <Button variant="contained" className={classes.button}>
              {fillLink}
            </Button>
          </Link>
        </Typography>
      </Grid>
    );
  };

  return (
    <>
      <Hidden smDown>
        <Grid container className={classes.gridContainer}>
          {props.side === "left" && (
            <>
              {makeImage("half")}
              {makeDescription()}
            </>
          )}
          {props.side !== "left" && (
            <>
              {makeDescription()}
              {makeImage("half")}
            </>
          )}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <Grid container className={classes.gridContainer}>
          {makeImage("full")}
          {makeDescription()}
        </Grid>
      </Hidden>
    </>
  );
};

export default HomeGrid;
