import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { LanguageContext } from "../utils/contexts";
import { localize, getAllNews } from "../utils/translator";

import { Typography } from "@material-ui/core";
import { RadioButtonChecked, RadioButtonUnchecked } from "@material-ui/icons";

const NewsCarousel = (props) => {
  const { language } = useContext(LanguageContext);

  const newsList = getAllNews(language);

  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      renderIndicator={(clickHandler, isSelected, index, label) => {
        if (isSelected) {
          return <RadioButtonChecked />;
        } else {
          return <RadioButtonUnchecked onClick={clickHandler} />;
        }
      }}
      infiniteLoop
      autoPlay
    >
      {newsList.map((news, index) => (
        <div key={`newsCarousel${index}`}>
          <img src={news.cover || "http://placehold.it/400x150"} alt="" />
          <div
            className="legend"
            style={{
              backgroundColor: "#293039",
              opacity: "1.0",
            }}
          >
            <Typography variant="body1" align="justify">
              {news.title}
            </Typography>
            <Typography variant="body1" align="justify">
              <Link to={`/news/${news["url"]}`} style={{ color: "white" }}>
                {localize("news.readmore", language) + "..."}
              </Link>
            </Typography>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default NewsCarousel;
