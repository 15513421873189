const neuronnFR = require("./neuronnFR.json");
const neuronnEN = require("./neuronnEN.json");
const vivatechFR = require("./vivatechFR.json");
const vivatechEN = require("./vivatechEN.json");
const expoprotectionFR = require("./expoprotectionFR.json");
const expoprotectionEN = require("./expoprotectionEN.json");
const FranceisAIFR = require("./FranceisAIFR.json");
const FranceisAIEN = require("./FranceisAIEN.json");
const newsList = {
  fr: {
    FranceisAI: FranceisAIFR,
    expoprotection: expoprotectionFR,
    vivatech: vivatechFR,
    neuronn: neuronnFR,
  },
  en: {
    FranceisAI: FranceisAIEN,
    expoprotection: expoprotectionEN,
    vivatech: vivatechEN,
    neuronn: neuronnEN,
  },
};

export default newsList;
