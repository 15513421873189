import { useContext } from "react";
import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Grid, Hidden, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "table",
    width: "80%",
    padding: theme.spacing(2, 4),
    marginTop: theme.spacing(6),
    marginLeft: "auto",
    marginBottom: theme.spacing(6),
    marginRight: "auto",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#0b82b1",
  },
}));

const CenteredContent = (props) => {
  const classes = useStyles();
  const { language } = useContext(LanguageContext);

  let margin = 2;
  if (props.hasOwnProperty("margin")) {
    margin = props.margin === 0 ? false : props.margin;
  }

  return (
    <div className="centeredContent">
      {props.title && (
        <Typography
          variant={props.variant || "h3"}
          align={props.align || "center"}
          className={props.noStyle ? "" : classes.section}
        >
          {props.noTranslate ? props.title : localize(props.title, language)}
        </Typography>
      )}
      <Grid container>
        <Hidden smDown>
          <Grid item xs={margin}></Grid>
        </Hidden>
        <Grid item xs={12} md={12 - 2 * margin}>
          {props.children}
        </Grid>
        <Hidden smDown>
          <Grid item xs={margin}></Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

export default CenteredContent;
