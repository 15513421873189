import React, { useContext } from "react";
import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

const FlatButton = (props) => {
  const { language } = useContext(LanguageContext);

  return (
    <Snackbar
      open={props.snackbarOpen}
      autoHideDuration={6000}
      onClose={props.handleClose}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={props.handleClose}
        severity={props.snackbarSeverity}
      >
        {localize(props.snackbarMessage, language)}
      </MuiAlert>
    </Snackbar>
  );
};

export default FlatButton;
