import React, { useContext } from "react";
import { Link, Redirect, useParams } from "react-router-dom";

import { LanguageContext } from "../utils/contexts";
import { localize, getOneArticles } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Button, Container, Typography } from "@material-ui/core";

import CenteredContent from "../components/CenteredContent";
import Markdown from "../components/Markdown";

const useStyles = makeStyles((theme) => ({
  button: {
    color: "white",
    backgroundColor: "#293039",
    margin: theme.spacing(2, 0, 0),
    maxWidth: "20em",
    "&:hover": {
      backgroundColor: "#146B9C",
    },
  },
  content: {
    margin: theme.spacing(2, 0),
  },
  title: {
    fontWeight: "bold",
    margin: theme.spacing(6, 0),
  },
}));

const NewsPage = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  const { articleName } = useParams();

  const article = getOneArticles(language, articleName);
  if (article === null) {
    return <Redirect to="/news" />;
  }

  return (
    <Container component="main" maxWidth="xl">
      <CenteredContent title={article.title} noTranslate margin={1}>
        {article.content.map((row, index) => (
          <Markdown key={`newsArticle${index}`}>{row}</Markdown>
        ))}

        <Typography variant="body1" align="center">
          <Link to="/news">
            <Button variant="contained" className={classes.button}>
              {localize("news.goback", language)}
            </Button>
          </Link>
        </Typography>
      </CenteredContent>
    </Container>
  );
};

export default NewsPage;
