import React, { useContext } from "react";
import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const FlatButton = (props) => {
  const { language } = useContext(LanguageContext);

  const styles = makeStyles((theme) => ({
    currentButton: {
      color: "white",
      backgroundColor: props.meta.color(theme),
      margin: theme.spacing(2, 0, 1),
      "&:hover": {
        backgroundColor: "#146B9C",
      },
    },
  }))();

  const handleAction = (target) => {
    return (e) => {
      e.preventDefault();
      target(e);
    };
  };

  return (
    <Button
      type="submit"
      variant="contained"
      color="secondary"
      className={styles.currentButton}
      onClick={handleAction(props.meta.target)}
      fullWidth
    >
      {localize(props.meta.name, language)}
    </Button>
  );
};

export default FlatButton;
