const AIfutureFR = require("./AIfutureFR.json");
const AIfutureEN = require("./AIfutureEN.json");
const robotFR = require("./robotFR.json");
const robotEN = require("./robotEN.json");
const whatIsAIFR = require("./whatIsAIFR.json");
const whatIsAIEN = require("./whatIsAIEN.json");
const RobotsystemesdesecuriteFR = require("./RobotsystemesdesecuriteFR.json");
const RobotsystemesdesecuriteEN = require("./RobotsystemesdesecuriteEN.json");
const MarcherobotiqueFR = require("./MarcherobotiqueFR.json");
const MarcherobotiqueEN = require("./MarcherobotiqueEN.json");

const articlesList = {
  fr: {
    Marcherobotique : MarcherobotiqueFR,
    Robotsystemesdesecurite: RobotsystemesdesecuriteFR,
    robot: robotFR,
    aifuture: AIfutureFR,
    whatIsAI: whatIsAIFR
   
  },
  en: {
    Marcherobotique : MarcherobotiqueEN,
    Robotsystemesdesecurite: RobotsystemesdesecuriteEN,
    robot: robotEN,
    aifuture: AIfutureEN,
    whatIsAI: whatIsAIEN
   
  },
};

export default articlesList;
