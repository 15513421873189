import React, { useContext } from "react";
import { LanguageContext } from "../utils/contexts";

import { ButtonGroup, Button } from "@material-ui/core";
import { IconFlagFR, IconFlagUK } from "material-ui-flags";

const LanguageSwitcher = (props) => {
  const { changeLanguage } = useContext(LanguageContext);

  return (
    <ButtonGroup>
      <Button
        aria-label="switch to french"
        style={{ border: "0" }}
        onClick={() => changeLanguage("fr")}
      >
        <IconFlagFR />
      </Button>
      <Button
        aria-label="switch to english"
        style={{ border: "0" }}
        onClick={() => changeLanguage("en")}
      >
        <IconFlagUK />
      </Button>
    </ButtonGroup>
  );
};

export default LanguageSwitcher;
