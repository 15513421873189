import React, { useContext } from "react";

import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { Grid, TextField } from "@material-ui/core";

const TextFieldInGrid = (props) => {
  const { language } = useContext(LanguageContext);

  const sm = props.fullwidth ? 12 : 6;

  let otherProps = {};
  if (props.inputProps) otherProps.InputProps = props.inputProps;
  if (!props.notRequired) otherProps.required = true;
  if (props.multiline) {
    otherProps.multiline = true;
    otherProps.rows = 4;
  }
  if (props.onChange) otherProps.onChange = props.onChange;

  return (
    <Grid item xs={12} sm={sm}>
      <TextField
        error={props.error}
        id={props.name}
        name={props.name}
        label={localize(props.label, language)}
        {...otherProps}
        variant="outlined"
        size="small"
        fullWidth
      />
    </Grid>
  );
};

export default TextFieldInGrid;
