import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Container,
  Fab,
  Hidden,
  IconButton,
  List,
  Toolbar,
} from "@material-ui/core";
import { Home, KeyboardArrowUp } from "@material-ui/icons";

import { availablePages, menuButtons } from "../assets/menuButtons";

import BackToTop from "./BackToTop";
import HideOnScroll from "./HideOnSCroll";
import LanguageSwitcher from "./LanguageSwitcher";
import MenuButton from "./MenuButton";
import SideDrawer from "./SideDrawer";

const useStyles = makeStyles({
  linkText: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "white",
  },
  navbarDisplayFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
  navListDisplayFlex: {
    display: "flex",
    justifyContent: "space-between",
  },
});

const TopMenu = (props) => {
  const classes = useStyles();

  return (
    <>
      <HideOnScroll>
        <AppBar position="fixed" style={{ backgroundColor: "#293039" }}>
          <Toolbar component="nav">
            <Container maxWidth="xl" className={classes.navbarDisplayFlex}>
              <IconButton edge="start" aria-label="home">
                <Link to="/" style={{ color: "white" }}>
                  <Home fontSize="large" />
                </Link>
              </IconButton>
              <Hidden smDown>
                <List
                  component="nav"
                  aria-labelledby="main navigation"
                  className={classes.navListDisplayFlex}
                >
                  {availablePages.map((menuButton, index) => (
                    <MenuButton meta={menuButtons[menuButton]} key={`headerButton${index}`} />
                  ))}
                </List>
              </Hidden>
              <LanguageSwitcher />
              <Hidden mdUp>
                <SideDrawer />
              </Hidden>
            </Container>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar id="back-to-top-anchor" />

      <BackToTop>
        <Fab
          style={{ backgroundColor: "#293039", color: "white" }}
          size="large"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUp />
        </Fab>
      </BackToTop>
    </>
  );
};

export default TopMenu;
