import React from "react";

const LanguageContext = React.createContext({
  language: "fr",
  changeLanguage: (newLang) => {},
});

const AlertContext = React.createContext({
  open: false,
  message: "",
  severity: "",
  notify: (message, severity) => {},
  recall: () => {},
});

export { AlertContext, LanguageContext };
