import React, { useContext } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Paper, Typography } from "@material-ui/core";
import {
  Adjust,
  BatteryChargingFull,
  Build,
  Business,
  Forum,
  Group,
  Lock,
  NotificationsActive,
  RadioButtonChecked,
  RadioButtonUnchecked,
  Search,
  Security,
  Visibility,
} from "@material-ui/icons";

import BlinkingE4 from "../components/BlinkingE4";
import CenteredContent from "../components/CenteredContent";

const useStyles = makeStyles((theme) => ({
  assetIcon: {
    fontSize: "4em",
    color: "#146B9C",
  },
  bordered: {
    border: "1px solid #146B9C",
  },
  carouselIcon: {
    position: "relative",
    top: theme.spacing(1),
    width: theme.typography.h4.fontSize,
    height: theme.typography.h4.fontSize,
    marginRight: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(2),
  },
  slide: {
    padding: theme.spacing(2),
  },
  title: {
    color: "#146B9C",
    fontWeight: "bold",
    margin: theme.spacing(3, 0),
    verticalAlign: "center",
  },
  verticalMargin: {
    margin: theme.spacing(6, 0),
  },
  verticalSpace: {
    height: theme.spacing(4),
  },
  imageContainer: {
    position: "relative",
    textAlign: "center",
  },
  imageFull: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  imageText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#293039",
    borderRadius: "20px",
    boxShadow: "5px 5px 5px rgba(255, 255, 255, 0.4)",
    padding: theme.spacing(4),
    "&:hover": {
      backgroundColor: "#0b82b1",
    },
  },
}));

const ProductPage = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  // const [E4PresentationIdx, setE4PresentationIdx] = useState(0);
  // const [carouselHeight, setCarouselHeight] = useState(0);
  // const [E4PresentationElementTop, setE4PresentationElementTop] = useState(0);

  // useEffect(() => {
  //   let E4PresentationElement = document.querySelector(
  //     "#E4PresentationCarousel"
  //   );
  //   let elementHeight = E4PresentationElement.clientHeight; // height with padding
  //   var computedStyle = getComputedStyle(E4PresentationElement);
  //   elementHeight -= parseFloat(computedStyle.paddingTop);
  //   elementHeight -= parseFloat(computedStyle.paddingBottom);
  //   setCarouselHeight(elementHeight);

  //   window.addEventListener("scroll", handleScroll);
  // }, []);

  const assetIcons = [
    <Business className={classes.assetIcon} />,
    <Group className={classes.assetIcon} />,
    <Lock className={classes.assetIcon} />,
    <Adjust className={classes.assetIcon} />,
  ];

  const carouselIcons = [
    <Forum className={classes.carouselIcon} />,
    <NotificationsActive className={classes.carouselIcon} />,
    <Search className={classes.carouselIcon} />,
    <Visibility className={classes.carouselIcon} />,
    <BatteryChargingFull className={classes.carouselIcon} />,
    <Build className={classes.carouselIcon} />,
    <Security className={classes.carouselIcon} />,
  ];

  // function handleScroll(event) {
  //   let eventY = window.scrollY;
  //   if (!document.querySelector("#E4PresentationStart")) return;
  //   if (!document.querySelector("#E4PresentationEnd")) return;
  //   let e4ElementTop = document
  //     .querySelector("#E4PresentationStart")
  //     .getBoundingClientRect().height;
  //   let e4ElementBottom = document
  //     .querySelector("#E4PresentationEnd")
  //     .getBoundingClientRect().height;
  //   let E4PresentationElement = document.querySelector(
  //     "#E4PresentationCarousel"
  //   );

  //   var computedStyle = getComputedStyle(E4PresentationElement);
  //   let elementHeight = E4PresentationElement.clientHeight; // height with padding
  //   elementHeight -=
  //     parseFloat(computedStyle.paddingTop) +
  //     parseFloat(computedStyle.paddingBottom);

  //   if (eventY < e4ElementBottom && eventY > e4ElementTop) {
  //     if (eventY - e4ElementTop - elementHeight > 0)
  //       setE4PresentationElementTop(eventY - e4ElementTop - elementHeight);

  //     let scrollPrecent =
  //       (eventY - e4ElementTop) / (e4ElementBottom - e4ElementTop);
  //     setE4PresentationIdx(
  //       parseInt(localize("product.features", language).length * scrollPrecent)
  //     );
  //   }
  // }

  return (
    <>
      <div className={classes.imageContainer}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/E4_action.png`}
          className={classes.imageFull}
          alt=""
        />
        <Typography
          variant="h4"
          align="center"
          className={classes.imageText}
          onClick={() =>
            window
              .open(
                "https://docs.google.com/forms/d/e/1FAIpQLSdWRl4qgWP7wKl-XBe4NX1geH6gprcHsE-1VEloJTB8MVUxPQ/viewform",
                "_blank"
              )
              .focus()
          }
        >
          {localize("product.callout", language)}
        </Typography>
      </div>
      <div className={classes.verticalSpace}></div>

      <Container component="main" maxWidth="xl">
        <CenteredContent title="" margin={1}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {localize("product.assetsTitle", language).map((row, index) => (
                <Typography
                  variant="h5"
                  align="center"
                  className={classes.content}
                  key={`productTypography${index}`}
                >
                  {row}
                </Typography>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {localize("product.assets", language).map((asset, index) => (
                  <Grid item xs={12} md={3} key={`productGrid${index}`}>
                    <Typography variant="h5" align="center">
                      {assetIcons[index]}
                    </Typography>
                    <Paper elevation={3} className={classes.bordered}>
                      <Typography
                        variant="h5"
                        align="center"
                        className={classes.content}
                        style={{ height: 80 }}
                      >
                        {asset[0]}
                      </Typography>
                      <div className={classes.bordered}></div>
                      <Typography
                        variant="h5"
                        align="center"
                        className={classes.content}
                        style={{ height: 80 }}
                      >
                        {asset[1]}
                      </Typography>
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* <div id="E4PresentationStart"></div> */}
            <Grid
              // id="E4Presentation"
              item
              xs={12}
              className={classes.verticalMargin}
              // style={{ height: 2 * carouselHeight, position: "relative" }}
            >
              <div
              // id="E4PresentationCarousel"
              // style={{ marginTop: E4PresentationElementTop }}
              >
                <Carousel
                  showThumbs={false}
                  showIndicators={true}
                  showStatus={false}
                  renderIndicator={(clickHandler, isSelected, index, label) => {
                    if (isSelected) {
                      return <RadioButtonChecked />;
                    } else {
                      return <RadioButtonUnchecked onClick={clickHandler} />;
                    }
                  }}
                  infiniteLoop
                  autoPlay
                  // transitionTime={0}
                  // selectedItem={E4PresentationIdx}
                >
                  {localize("product.features", language).map(
                    (feature, index) => (
                      <div
                        key={`productCarousel${index}`}
                        className={classes.slide}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} md={6}>
                            <BlinkingE4 id={index} />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Typography
                              variant="h4"
                              align="center"
                              className={classes.title}
                            >
                              {carouselIcons[index]}
                              {feature[0]}
                            </Typography>
                            <Typography
                              variant="h5"
                              align="justify"
                              className={classes.content}
                            >
                              {feature[1]}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    )
                  )}
                </Carousel>
              </div>
            </Grid>
            {/* <div id="E4PresentationEnd"></div> */}
          </Grid>
        </CenteredContent>
      </Container>
    </>
  );
};

export default ProductPage;
