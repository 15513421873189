import React, { useContext } from "react";

import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";

import CenteredContent from "../components/CenteredContent";
import HeroPage from "./HeroPage";
import HomeGrid from "../components/HomeGrid";
import NewsCarousel from "../components/NewsCarousel";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5, 0),
  },
  title: {
    fontWeight: "bold",
    margin: theme.spacing(4, 0),
    verticalAlign: "center",
  },
}));

const HomePage = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <>
      <HeroPage />
      <Container component="main" maxWidth="xl" className={classes.container}>
        <CenteredContent title="" margin={2}>
          <HomeGrid
            name="product"
            image="E4_openSpace.jpg"
            link="product"
            side="left"
            width={6}
          />
          <HomeGrid
            name="team"
            image="about_team.jpg"
            link="about"
            side="right"
            width={6}
          />
          <Typography variant="h4" align="center" className={classes.title}>
            {localize("news.title", language)}
          </Typography>
          <NewsCarousel />
          <br style={{ marginBottom: "5em" }} />
          <HomeGrid
            name="invest"
            image="E4_text.png"
            link="invest"
            side="right"
            width={6}
          />
        </CenteredContent>
      </Container>
    </>
  );
};

export default HomePage;
