import React, { useContext } from "react";
import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";
import FooterLinkButton from "../components/FooterLinkButton";

import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  ButtonGroup,
  Container,
  Toolbar,
  Typography,
  Grid,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(0.5, 0),
    margin: theme.spacing(2, 0, 0, 0),
    backgroundColor: "#293039",
  },
}));

const mediaButtons = [
  {
    coloredIcon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/linkedinColored.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    icon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/linkedinBW.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    link: "https://www.linkedin.com/company/a-i-mergence/",
  },
  {
    coloredIcon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/twitterColored.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    icon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/twitterBW.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    link: "https://twitter.com/ai_mergence",
  },
  {
    coloredIcon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/facebookColored.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    icon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/facebookBW.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    link: "https://www.facebook.com/A.I.Mergence/",
  },
  {
    coloredIcon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/instagramColored.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    icon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/instagramBW.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    link: "https://www.instagram.com/a.i.mergence/",
  },
  {
    coloredIcon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/youtubeColored.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    icon: (
      <img
        src={`${process.env.PUBLIC_URL}/assets/icons/youtubeBW.png`}
        width="30px"
        style={{ width: "30px" }}
        alt=""
      />
    ),
    link: "https://www.youtube.com/channel/UCQWhx67FYu0CRnxdJk2_COw",
  },
];

const Footer = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <AppBar position="static" classes={{ root: classes.footer }}>
      <Toolbar component="nav">
        <Container maxWidth="md">
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={12}>
              <ButtonGroup>
                {mediaButtons.map((value, index) => (
                  <FooterLinkButton
                    link={value.link}
                    icon={value.icon}
                    coloredIcon={value.coloredIcon}
                    index={index}
                    key={`footer-${index}`}
                  />
                ))}
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                color="inherit"
                className={classes.footer}
              >
                © {new Date().getFullYear()} A.I.Mergence.&nbsp;
                {localize("copyright", language)}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
