import React, { useContext } from "react";
import { LanguageContext } from "../utils/contexts";
import { localize } from "../utils/translator";

import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography } from "@material-ui/core";

import CenteredContent from "../components/CenteredContent";
import ExpertCard from "../components/ExpertCard";
import Markdown from "../components/Markdown";

const useStyles = makeStyles((theme) => ({
  content: {
    margin: theme.spacing(2, 0),
    fontSize: "1.2rem",
  },
  imageContainer: {
    position: "relative",
    textAlign: "center",
  },
  imageFull: {
    width: "100%",
    margin: 0,
    padding: 0,
  },
  imageText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    padding: theme.spacing(4),
  },
  section: {
    display: "table",
    marginTop: theme.spacing(6),
    marginLeft: "auto",
    marginBottom: theme.spacing(6),
    marginRight: "auto",
    textTransform: "uppercase",
    fontWeight: "bold",
    color: "white",
    backgroundColor: "#0b82b1",
    padding: theme.spacing(2, 4),
  },
  title: {
    fontWeight: "bold",
    margin: theme.spacing(6, 0),
  },
}));

const ExpertPage = (props) => {
  const { language } = useContext(LanguageContext);
  const classes = useStyles();

  return (
    <>
      <div className={classes.imageContainer}>
        <img
          src={`${process.env.PUBLIC_URL}/assets/images/expert_banner.jpg`}
          className={classes.imageFull}
          alt=""
        />
        <Typography variant="h3" align="center" className={classes.imageText}>
          {localize("expert.title", language)}
        </Typography>
      </div>

      <Container component="main" maxWidth="xl">
        <CenteredContent margin={1}>
          {localize("expert.description", language).map((row, index) => (
            <Markdown
              variant="h5"
              align="center"
              key={`expertDescription${index}`}
            >
              {row}
            </Markdown>
          ))}
        </CenteredContent>

        <CenteredContent title="expert.expertisesTitle" margin={1}>
          <Grid container spacing={3}>
            {localize("expert.expertises", language).map((expertise, index) => (
              <Grid item xs={12} md={3} key={`expertExpertisesGrid${index}`}>
                <Typography variant="h5" align="center">
                  <img
                    src={`${process.env.PUBLIC_URL}/assets/images/${expertise.image}`}
                    style={{ height: 100 }}
                    alt=""
                  />
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  className={classes.title}
                  style={{ height: 80 }}
                >
                  {expertise.title}
                </Typography>
                <Typography
                  variant="h5"
                  align="center"
                  className={classes.content}
                >
                  {expertise.description}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CenteredContent>

        <CenteredContent title="expert.technologiesTitle" margin={1}>
          <Grid container justify="space-evenly" spacing={3}>
            {localize("expert.technologies", language).map(
              (technology, index) => (
                <Grid
                  item
                  xs={12}
                  md={5}
                  key={`expertTechnologiesGrid${index}`}
                >
                  <Typography variant="h5" align="center">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/${technology.image}`}
                      style={{ height: 100 }}
                      alt=""
                    />
                  </Typography>
                  <Typography
                    variant="h5"
                    align="center"
                    className={classes.title}
                    style={{ height: 80 }}
                  >
                    {technology.title}
                  </Typography>
                  {technology.description.map((row, rowIndex) => (
                    <Markdown key={`${rowIndex}row`}>{row}</Markdown>
                  ))}
                </Grid>
              )
            )}
          </Grid>
        </CenteredContent>

        <CenteredContent title="expert.interventionTitle" margin={1}>
          <Typography variant="h5" align="center" className={classes.title}>
            {localize("expert.interventionSubtitle", language)}
          </Typography>
          {localize("expert.intervention", language).map((expert, index) => (
            <ExpertCard
              expert={expert}
              side={index % 2 ? "left" : "right"}
              key={`expertExpert${index}`}
            />
          ))}
        </CenteredContent>
      </Container>
    </>
  );
};

export default ExpertPage;
