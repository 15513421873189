import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import { Card, CardMedia, CardContent, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  cardName: {
    height: 60,
  },
  cardPosition: {
    height: 50,
    fontSize: "1rem",
  },
  media: {
    height: 250,
    WebKitFilter: "grayscale(100%)",
    MozFilter: "grayscale(100%)",
    msFilter: "grayscale(100%)",
    filter: "grayscale(100%)",
    WebKitTransition: "1s",
    MozTransition: "1s",
    msTransition: "1s",
    transition: "1s",
    "&:hover": {
      WebKitFilter: "none",
      MozFilter: "none",
      msFilter: "none",
      filter: "none",
      WebKitTransition: "1s",
      MozTransition: "1s",
      msTransition: "1s",
      transition: "1s",
    },
  },
  root: {
    maxWidth: "95%",
  },
}));

const MemberCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root} id={`member${props.meta.name}`}>
      <CardMedia
        className={classes.media}
        image={`${process.env.PUBLIC_URL}/assets/images/${props.meta.image}`}
        title={props.meta.name}
      />

      <CardContent style={{ backgroundColor: "#293039", color: "white" }}>
        <Typography
          gutterBottom
          variant="h5"
          component="h2"
          className={classes.cardName}
        >
          {props.meta.name}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          className={classes.cardPosition}
        >
          {props.meta.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default MemberCard;
