import React, { Suspense, useRef, useState } from "react";
import { Canvas, useLoader } from "react-three-fiber";
// import { STLLoader } from "three/examples/jsm/loaders/STLLoader";

// import Coque from "../assets/Coque.stl";
// import Fisheye from "../assets/Fisheye.stl";
// import HautParleur from "../assets/HautParleur.stl";
// import RoueAR from "../assets/RoueAR.stl";
import RobotE4 from '../assets/AIMergence_hd.glb'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

function Box(props) {
  const mesh = useRef();
  const [state, setState] = useState({ isHovered: false, isActive: false });

  return (
    <mesh
      {...props}
      ref={mesh}
      rotateY={90}
      scale={state.isHovered ? [0.021, 0.021, 0.021] : [1, 1, 1]}
    >
      <boxBufferGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color={state.isActive ? "#1F1F1F" : "#1F1F1F"} />
    </mesh>
  );
}

function E43DModel(props) {
  const mesh = useRef();
  // const coque = useLoader(STLLoader, Coque);
  // const roue = useLoader(STLLoader, RoueAR);
  // const hautParleur = useLoader(STLLoader, HautParleur);
  // const fishEye = useLoader(STLLoader, Fisheye);

  // const normal_scale = [0.05, 0.05, 0.05];
  // const scale_on_hover = [0.051, 0.051, 0.051];

  // <meshStandardMaterial color={"white"} />;
  const gltf = useLoader(GLTFLoader, RobotE4);
  return <primitive ref={mesh}  position={[0, 0, -4]} scale={[15, 15, 15]} object={gltf.scene} rotation={props.rotation} {...props}/>
}

const E43DView = (props) => {
  const [rotation_x, setRotationX] = useState(0);
  const [rotation_y, setRotationY] = useState(0);
  const [rotation_z, setRotationZ] = useState(0);
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  let old_x = 0;
  let old_y = 0;
  const handle_mouse_move = (event) => {
    if (clicked) {
      if (old_x !== 0 && old_y !== 0) {
        let rotation_z_cpy = rotation_z;
        let rotation_x_cpy = rotation_x;
        rotation_z_cpy -= (old_x - event.clientX) / 100;
        rotation_x_cpy -= (old_y - event.clientY) / 100;
        setRotationZ(rotation_z_cpy);
        setRotationX(rotation_x_cpy);
      }
      old_x = event.clientX;
      old_y = event.clientY;
    }
  };

  return (
    <Canvas
      style={{ width: "100%", backgroundColor: "#293039" }}
      onPointerUp={(e) => setClicked(false)}
      onPointerDown={(e) => setClicked(true)}
      onPointerOver={(e) => setHovered(true)}
      onPointerOut={(e) => {
        setClicked(false);
        setHovered(false);
      }}
      onPointerEnter={(e) => setHovered(true)} // see note 1
      onPointerLeave={(e) => {
        setClicked(false);
        setHovered(false);
      }} // see note 1
      onPointerMove={(e) => handle_mouse_move(e)}
    >
      {/* Place lights */}
      <ambientLight intensity={0.5} />
      <spotLight position={[0, 0, 0]} angle={-180} penumbra={1} />
      <pointLight position={[0, 0, -2.5]} />
      <Suspense fallback={<Box />}>
        <E43DModel
          rotation={[rotation_x, rotation_y, rotation_z]}
          hovered={hovered}
        />
      </Suspense>
    </Canvas>
  );
};

export default E43DView;
