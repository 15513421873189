import { Button } from "@material-ui/core"
import { useState } from "react"



const FooterLinkButton = (props) => {
    const [colored, setColored] = useState(false)
    return (
        <Button
            variant="text"
            key={`footerButton${props.index}`}
            color="inherit"
            onClick={() => {
                window.open(props.link)
                setColored(false)
            }}
            onMouseEnter={()=>setColored(true)}
            onMouseLeave={()=>setColored(false)}
        >
            {
                colored === false ? props.icon: props.coloredIcon
            }
        </Button>
    )
}

export default FooterLinkButton